import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import SideBar from '../component/SideBar';
import NavBar from "./NavBar";

function PrivateRoute({ isLogged }) {
    
    useEffect(() => {
    }, [window.location.pathname]);

    const token = window.localStorage.getItem('accessToken');
    const permission = window.localStorage.getItem('permission');
    let logged = false;
    if (token !== null && permission === 'Admin') {
        logged = true;
    } else {
        logged = false;
        localStorage.clear()
    }
    return logged ? <div class="main_sec"><SideBar /><section class="main_content"><NavBar /><Outlet /></section></div> : <Navigate to="/" />;
}

export default PrivateRoute;