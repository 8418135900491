import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

function Protected({ isSignedIn, children }) {
    const token = window.localStorage.getItem('accessToken');
    const permission = window.localStorage.getItem('permission');
    let logged = false;
    if (token !== null && permission === 'Admin') {
        logged = true;
    } else {
        logged = false;
        localStorage.clear()
    }

    if (!logged) {
        return <Navigate to="/" replace />
    }
    return children;
}
export default Protected