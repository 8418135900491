import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "../component/Card";
import { postDataService } from "../component/userService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Enable2fa(props) {
    const navigate = useNavigate();

    const [otp, setOtp] = useState(null);
    const [secret, setSecret] = useState(null);

    useEffect(() => {
        getIndividualData();
    }, [navigate]);


    async function getIndividualData() {
        var data = {
            status: true
        }
        await postDataService(`generate-2fa-secret`, new URLSearchParams(data))
            .then(response => {
                if (response.status === "success" && !response.twofaEnabled) {
                    const { secret, qrImageDataUrl } = response;
                    setSecret({ secret, qrImageDataUrl });
                    // toast.success(response.message);
                } else {
                    toast.dismiss();
                    toast.error(response.message);
                }
            });
    }

    async function onClick(event) {
        event.preventDefault();

        var data = {
            token: otp
        }

        await postDataService(`verify-otp`, new URLSearchParams(data))
            .then(response => {
                if (response.status === "success" && response.twofaEnabled) {
                    localStorage.setItem('twofa', true);
                    toast.success(response.message);
                    setTimeout(() => {
                        navigate("/dashboard");
                    }, 3000);
                } else {
                    toast.dismiss();
                    toast.error(response.message);
                }
            });
    }

    return (
        <>
            <div>
                <ToastContainer closeButton={true} position="top-right" />
            </div>
            <Card>
                {secret && (
                    <>
                        <h2 className="heading_typ4">Enable 2FA</h2>

                        <div className="sub_heading">
                            Scan the following QR code or manually input the secret in{" "}
                            <a
                                target="_blank"
                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                            >
                                Google Authenticator
                            </a>{" "}
                            and then enter an OTP below to enable 2-Factor Authentication
                        </div>

                        <div className="search_s_img_qr_code">
                            <img src={secret.qrImageDataUrl} />

                            <p className="mt-2">
                                Secret: <b>{secret.secret}</b>
                            </p>
                        </div>

                        <form className="search_s_qr_code">
                            <div className="form_field_container">
                                <label>OTP</label>
                                <input className="form-control" type="text" onChange={(e) => setOtp(e.target.value)} />
                            </div>
                            <div className="scd_sec_btn">

                                <button className="btn btn_primary btn-round mt-3 mb-3" type="submit" onClick={onClick}>
                                    Enable 2FA
                                </button>
                                <div className="back_btn">
                                    <Link to={"/dashboard"}>Go back to dashboard</Link>
                                </div>
                            </div>
                        </form>
                    </>
                )}
            </Card>
        </>
    );
}
