import Swal from "sweetalert2";

// const serverApi = 'http://3.14.175.22:4747';
// const serverApi = 'http://localhost:4747';
const serverApi = 'https://api.malsearch.com';

// Function to handle generic fetch calls
const handleFetch = async (url, options) => {
  try {
    const response = await fetch(url, options);
    const data = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        const result = await Swal.fire({
          title: data.message,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: `Yes!`
        });
        if (result.isConfirmed) {
          localStorage.clear();
          return window.location.href = "/";
        }
      } else {
        console.log(response.status)
      }
    }

    return data;
  } catch (error) {
    throw new Error(error.message || 'Something went wrong');
  }
};

// Login
export async function loginUser(data) {
  const response = await fetch(`${serverApi}/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({
      email: data.email,
      password: data.password,
    })
  })
  return await response.json();
}

// ********************************** Common *********************************************
// Get Data Common
export async function getDataService(api) {
  const url = `${serverApi}/${api}`;

  const accessToken = window.localStorage.getItem('accessToken');

  const headers = {
    'Content-Type': 'application/json',
  };

  if (accessToken !== null) {
    headers['Authorization'] = 'Bearer ' + accessToken;
  }

  const options = {
    method: 'GET',
    headers: headers,
  };

  return handleFetch(url, options);
}

// Post Data Common
export async function postDataService(api, data) {
  const url = `${serverApi}/${api}`;
  const accessToken = window.localStorage.getItem('accessToken');

  const headers = {
    // 'Content-Type': 'application/json',
  };

  if (accessToken !== null) {
    headers['Authorization'] = 'Bearer ' + accessToken;
  }

  const options = {
    method: 'POST',
    headers: headers,
    body: data,
  };

  return handleFetch(url, options);
}

export const serverLink = serverApi;