import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import emailImage from "../assets/img/email.svg";
import lock from "../assets/img/lock.svg";
import { postDataService } from "../component/userService";

const Login = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [isAlertSuccess, setIsAlertSuccess] = useState('');
  const [isAlertError, setIsAlertError] = useState('');

  // Validation
  const handleValidation = (event) => {
    let formIsValid = false;
    if (!email) {
      setEmailError("Email is required!");
      return false;
    } else {
      setEmailError("");
      formIsValid = true;
    }
    if (!password) {
      setPasswordError("Password is required!");
      return false;
    } else {
      setPasswordError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (handleValidation()) {
      var user = {
        email: email,
        password: password
      }
      await postDataService('login', new URLSearchParams(user))
        .then(response => {
          if (response.status === 'success') {
            if (response.user.twofaEnabled) {
              localStorage.setItem(
                "loginStep2VerificationToken",
                response.user.loginStep2VerificationToken
              );
              navigate("/login-with-otp");
            } else {
              localStorage.setItem('accessToken', response.user.token);
              localStorage.setItem('userFirstName', response.user.firstName);
              localStorage.setItem('userLastName', response.user.lastName === undefined ? '' : response.user.lastName);
              localStorage.setItem('userId', response.user._id);
              localStorage.setItem('email', response.user.email);
              localStorage.setItem('userImage', response.user.image);
              localStorage.setItem('permission', response.user.permission);
              localStorage.setItem('twofa', response.user.twofaEnabled);
              navigate("/dashboard");
            }
          } else {
            setIsAlertSuccess('');
            setIsAlertError(response.message);
          }
        });
    }
  }

  return (
    <>
      <div className="main_sec_login">
        <div className="st_1">
          <div className="st_1_in">
            <h2 className="heading_typ1">Login to your account</h2>
            {isAlertError === '' ? '' :
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>{isAlertError}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{
                  "float": "right", "border": "none",
                  "background": "#f8d7da"
                }}>
                  <span aria-hidden="true" onClick={(e) => setIsAlertError('')}>&times;</span>
                </button>
              </div>
            }
            {isAlertSuccess === '' ? '' :
              <div class="alert alert-success alert-dismissible fade show" role="alert">
                <strong>{isAlertSuccess}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{
                  "float": "right", "border": "none",
                  "background": "rgb(209 231 221)"
                }}>
                  <span aria-hidden="true" onClick={(e) => setIsAlertSuccess('')}>&times;</span>
                </button>
              </div>
            }
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Email Address</label>
                    <div className="form-group-item">
                      <div className="form-group-img"><img src={emailImage} /></div>
                      <input type="text" placeholder="Enter email address" onChange={(e) => setEmail(e.target.value)} className="form-control" />
                    </div>
                    <small className="text-danger form-text">
                      {emailError}
                    </small>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Password</label>
                    <div className="form-group-item">
                      <div className="form-group-img"><img src={lock} /></div>
                      <input type="password" placeholder="Enter password" onChange={(e) => setPassword(e.target.value)} className="form-control" />
                    </div>
                    <small className="text-danger form-text">
                      {passwordError}
                    </small>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group text-end">
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <button onClick={handleSubmit} className="loginbutton btn btn_primary">Login</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};


export default Login;