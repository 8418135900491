
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { postDataService } from "./userService";
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

export default (props) => {
  const navigate = useNavigate();
  const [verificationCodeError, setVerificationCodeError] = useState('');
  const [verificationCode, setVerificationCode] = useState();
  const [twofaStatus, setTwofaStatus] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    console.log('window.localStorage.getItem()', window.localStorage.getItem('twofa'));
    setTwofaStatus(window.localStorage.getItem('twofa'));
  }, [window.localStorage.getItem('twofa')]);


  const userFirstName = window.localStorage.getItem('userFirstName');
  const userLastName = window.localStorage.getItem('userLastName');
  const ln = userLastName.split('')[0] === undefined ? '' : userLastName.split('')[0];
  const userEmail = window.localStorage.getItem('email');

  // Validation For 2FA Off
  const handleValidationAuthentication = (event) => {
    let formIsValid = false;
    if (!verificationCode) {
      setVerificationCodeError("Verification code is required!");
      return false;
    } else {
      setVerificationCodeError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const handleSubmitAuthenticationOff = async event => {
    event.preventDefault();
    if (handleValidationAuthentication()) {
      var data = {
        token: verificationCode,
        status: false
      }
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, two factor authentication off it!`
      }).then(async (result) => {
        if (result.isConfirmed) {
          await postDataService(`web/enable_two_factor_authentication`, new URLSearchParams(data))
            .then(response => {
              if (response.status === 'success') {
                setShow(false);
                localStorage.setItem('twofa', false);
                Swal.fire(
                  'Two Factor Authentication!',
                  "Off Successfully!",
                  'success'
                )
              } else {
                Swal.fire(
                  'Two Factor Authentication!',
                  response.message,
                  'error'
                )
              }
            });
        }
      })

    }
  }

  // Two-Factor Authentiction
  async function twoFactorAuthentiction(status) {
    if (status === 'enable') {
      navigate('/enable-two-factor');
    } else {
      setShow(true);
    }
  }

  return (
    <>
      <header class="header_sec">
        <div class="navbar_right ms-auto">
          <div class="navbar_item"><button className="btn btn_primary btn-round" onClick={(e) => twoFactorAuthentiction(window.localStorage.getItem('twofa') === 'true' ? 'disable' : 'enable')}>{window.localStorage.getItem('twofa') === 'true' ? 'Disable 2FA' : 'Enable 2FA'}</button></div>
          <div class="navbar_profile">
            <a href="#">
              <div class="navbar_profile_img"><h5>{userFirstName.split('')[0] + '' + ln}</h5></div>
              <div class="navbar_profile_con">
                <p class="navbar_profile_nm">{userFirstName + ' ' + userLastName}</p>
                <p>{userEmail}</p>
              </div>
            </a>
          </div>
        </div>
      </header>
      <Modal size="" className="modal-md custom-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} animation={false}>
        <form onSubmit={handleSubmitAuthenticationOff}>
          <Modal.Body className="tab-content">
            <label>Verification Code</label>
            <input type="text" onChange={(e) => setVerificationCode(e.target.value)} className="form-control" placeholder="Type Here" />
            <small className="text-danger form-text">
              {verificationCodeError}
            </small>
          </Modal.Body>
          <div className="cmodal-btns d-flex align-items-center justify-content-center">
            <div className="next">
              <button onClick={handleClose} className="btn btn-theme-subscription">Cancel</button>
            </div>
            <div className="pre">
              <button type="submit" className="btn btn-theme-subscription">Submit</button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};
