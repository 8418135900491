import React, { useState, useEffect } from "react";
import { getDataService, postDataService } from "../../component/userService";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MemberEdit = () => {
  const history = useNavigate();
  var data = useParams();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [status, setStatus] = useState();
  const [enterprisePlan, setEnterprisePlan] = useState('');
  const [planStatus, setPlanStatus] = useState();

  const [firstNameError, setFirstNameError] = useState();
  const [emailError, setEmailError] = useState();
  const [statusError, setStatusError] = useState();

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      getIndividualData();
    }
  }, [])

  async function getIndividualData() {
    await getDataService(`get_individual_member/${data.id}`)
      .then(response => {
        if (response.status === 'success') {
          setFirstName(response.data.userData.firstName);
          setLastName(response.data.userData.lastName);
          setEmail(response.data.userData.local.email);
          setStatus(response.data.userData.status);
          setEnterprisePlan(response.data.userData.enterprisePlan === true ? 'true' : 'false');
          setPlanStatus(response.data.planStatus);
        } else {
          console.log(response.message);
        }
      });
  }

  // Validation
  const handleValidation = (event) => {
    let formIsValid = false;
    if (!firstName) {
      setFirstNameError("First name is required!");
      return false;
    } else {
      setFirstNameError("");
      formIsValid = true;
    }

    if (!email) {
      setEmailError("Email is required!");
      return false;
    } else {
      setEmailError("");
      formIsValid = true;
    }

    if (!status) {
      setStatusError("Status is required!");
      return false;
    } else {
      setStatusError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (handleValidation()) {
      if (Object.keys(data).length !== 0) {
        var featureData = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          status: status,
          _id: data.id,
          enterprisePlan: enterprisePlan === 'true' ? true : false
        }

        await postDataService(`update_member`, new URLSearchParams(featureData))
          .then(response => {
            if (response.status === 'success') {
              toast.success(response.message);
              setTimeout(() => {
                history("/members");
              }, 3000);
            } else {
              toast.error(response.message);
            }
          });

      }

    }
  }

  return (
    <>
      <section class="content_sec">
        <ToastContainer closeButton={true} position="top-right" />
        <div class="content_table">
          <div class="content_table_card card">
            <div class="content_table_head">
              <div class="content_table_head_cn">
                <p>Edit Member</p>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div class="card upload_crad">
              <div class="upload_crad_in">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>First Name <span className="text-danger">*</span></label>
                      <div class="form_input">
                        <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} class="form-control" placeholder="Enter First Name" />
                        <small className="text-danger form-text">
                          {firstNameError}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Last Name </label>
                      <div class="form_input">
                        <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} class="form-control" placeholder="Enter Last Name" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Email <span className="text-danger">*</span></label>
                      <div class="form_input">
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} class="form-control" placeholder="Enter Email" />
                        <small className="text-danger form-text">
                          {emailError}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Status <span className="text-danger">*</span></label>
                      <select class="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option>Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                      <small className="text-danger form-text">
                        {statusError}
                      </small>
                    </div>
                  </div>
                  {planStatus ? ''
                    : <div class="col-md-4">
                      <div class="form-group">
                        <label>Enable Enterprise Plan </label>
                        <select onChange={(e) => setEnterprisePlan(e.target.value)} class="form-control" value={enterprisePlan}>
                          <option>Select option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>}
                </div>
                <div className="row">
                  <div class="cn_t_add">
                    <button type="submit" class="btn btn_primary submitButton">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};


export default MemberEdit;
