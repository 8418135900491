import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import edit from "../../assets/img/edit.svg";
import deleteNew from "../../assets/img/delete.svg";
import plus from "../../assets/img/plus.svg";
import { getDataService, postDataService } from "../../component/userService";
import Swal from "sweetalert2";
import LoaderComponent from "../../component/LoaderComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FeatureList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isData, setIsData] = useState(false);

    useEffect(() => {
        getData();
    }, [])

    // Get Feature List
    async function getData() {
        setLoading(true);
        await getDataService(`get_feature`)
            .then(response => {
                setLoading(false);
                if (response.status === 'success') {
                    setData(response.data);
                    if (response.data.length === 0) {
                        setIsData(true);
                    }
                } else {
                    toast.dismiss();
                    toast.error(response.message);
                }
            });
    }

    // Change Status
    async function changeStatus(_id, status) {
        var changeStatus = status;
        var statusData = {
            _id: _id,
            status: changeStatus
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await postDataService(`change_feature_status`, new URLSearchParams(statusData))
                    .then(response => {
                        if (response.status === 'success') {
                            Swal.fire(
                                changeStatus + '!',
                                response.message,
                                'success'
                            )
                            getData();
                        } else {
                            Swal.fire(
                                changeStatus + '!',
                                response.message,
                                'error'
                            )
                        }
                    });
            }
        })

    }

    return (
        <>
            <div>
                <LoaderComponent active={loading} />
            </div>
            <section class="content_sec">
                <ToastContainer closeButton={true} position="top-right" />
                <div class="content_table">
                    <div class="content_table_card card">
                        <div class="content_table_head">
                            <div class="content_table_head_cn">
                                <p>Features</p>
                            </div>
                            <div class="content_table_head_sort">
                                <div class="cn_t_sort input-group select-group">
                                </div>
                            </div>
                            <div class="content_table_head_search">
                                <div class="cn_t_search">
                                    <div class="marwal_r_search">
                                    </div>
                                </div>
                            </div>
                            <div class="content_table_head_add">
                                <div class="cn_t_add">
                                    <div class="cn_t_add_drp">
                                        <div class="dropdown">
                                            <Link to="/feature/add">
                                                <button type="button" class="btn btn_primary btn-round">
                                                    <img src={plus} /> Add New
                                                </button>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content_table_itm card">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>status</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => {
                                        return (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td class="table_small_data">{item.name}</td>
                                                <td class="table_small_data">{item.description}</td>
                                                <td>{item.status === 'Active' ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>}</td>
                                                <td class="action_td">
                                                    <div class="action_table">
                                                        <div class="action_table_itm"><Link to={"/feature/edit/" + item._id}><img src={edit} /></Link></div>
                                                        <div class="action_table_itm"><button onClick={(e) => { changeStatus(item._id, 'Deleted') }}><img src={deleteNew} /></button></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {isData && (
                                <p className="text-center mt-3">Record Not Found!</p>
                            )}
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
};


export default FeatureList;
