import React, { useEffect, useState } from "react";
import { getDataService, postDataService } from "../../component/userService";
import Select from 'react-select';
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const PlanAdd = () => {
  const history = useNavigate();
  var data = useParams();
  const [featureData, setFeatureData] = useState([]);

  const [name, setName] = useState();
  const [type, setType] = useState();
  const [duration, setDuration] = useState();
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [description, setDescription] = useState();
  const [selectedFeature, setSelectedFeature] = useState();


  const [nameError, setNameError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [durationError, setDurationError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [selectedFeatureError, setSelectedFeatureError] = useState();


  useEffect(() => {

    if (Object.keys(data).length !== 0) {
      getIndividualData();
    }
    getFeatureList();
  }, [])

  // Get Feature List
  async function getFeatureList() {
    await getDataService(`get_feature_for_add_plan`)
      .then(response => {
        if (response.status === 'success') {
          setFeatureData(response.data);
        } else {
          console.log(response.message);
        }
      });
  }

  async function getIndividualData() {
    await getDataService(`get_individual_plan/${data.id}`)
      .then(async response => {
        if (response.status === 'success') {
          console.log(response.features);
          setSelectedFeature(response.features);
          setName(response.data.name);
          setType(response.data.type);
          setDuration(response.data.duration);
          setPrice(response.data.price);
          setDiscount(response.data.discount);
          setDescription(response.data.description);
        } else {
          console.log(response.message);
        }
      });
  }

  // Validation
  const handleValidation = (event) => {
    let formIsValid = false;
    if (!name) {
      setNameError("Name is required!");
      return false;
    } else {
      setNameError("");
      formIsValid = true;
    }
    if (!type) {
      setTypeError("Type is required!");
      return false;
    } else {
      setTypeError("");
      formIsValid = true;
    }
    if (!duration) {
      setDurationError("Duration is required!");
      return false;
    } else {
      setDurationError("");
      formIsValid = true;
    }
    if (type === 'price') {
      if (!price) {
        setPriceError("Price is required!");
        return false;
      } else {
        setPriceError("");
        formIsValid = true;
      }
    }
    if (!selectedFeature) {
      setSelectedFeatureError("Features is required!");
      return false;
    } else {
      setSelectedFeatureError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (handleValidation()) {
      if (Object.keys(data).length !== 0) {

        var planData = {
          name: name,
          type: type,
          duration: duration,
          price: price,
          discount: discount,
          feature: JSON.stringify(selectedFeature),
          description: description,
          _id: data.id
        }

        await postDataService(`update_plan`, new URLSearchParams(planData))
          .then(response => {
            if (response.status === 'success') {
              toast.success(response.message);
              history("/plan");
            } else {
              toast.error(response.message);
            }
          });

      } else {

        var planData = {
          name: name,
          type: type,
          duration: duration,
          price: price,
          discount: discount,
          feature: JSON.stringify(selectedFeature),
          description: description
        }

        await postDataService(`add_plan`, new URLSearchParams(planData))
          .then(response => {
            if (response.status === 'success') {
              toast.success(response.message);
              history("/plan");
            } else {
              toast.error(response.message);
            }
          });
      }

    }
  }

  return (
    <>
      <ToastContainer closeButton={true} position="top-right" />
      <section class="content_sec">
        <div class="content_table">
          <div class="content_table_card card">
            <div class="content_table_head">
              <div class="content_table_head_cn">
                <p>Add Plan</p>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div class="card upload_crad">
              <div class="upload_crad_in">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Plan Name <span className="text-danger">*</span></label>
                      <div class="form_input">
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} class="form-control" placeholder="Enter name of the plan..." />
                        <small className="text-danger form-text">
                          {nameError}
                        </small>
                      </div>
                    </div>
                  </div>
                  {Object.keys(data).length !== 0 ? '' :
                    <>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Plan Type <span className="text-danger">*</span></label>
                          <select class="form-control" value={type} onChange={(e) => setType(e.target.value)}>
                            <option>Select Plan Type</option>
                            <option value="custom">Custom</option>
                            <option value="price">Price</option>
                          </select>
                          <small className="text-danger form-text">
                            {typeError}
                          </small>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Plan Duration <span className="text-danger">*</span></label>
                          <select class="form-control" value={duration} onChange={(e) => setDuration(e.target.value)}>
                            <option>Select Plan Duration</option>
                            <option value="monthly">Monthly</option>
                            <option value="yearly">Yearly</option>
                          </select>
                          <small className="text-danger form-text">
                            {durationError}
                          </small>
                        </div>
                      </div>
                      {type === 'price' ?
                        <div class="col-md-4">
                          <div class="form-group">
                            <label>Price <span className="text-danger">*</span></label>
                            <div class="form_input">
                              <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} class="form-control" placeholder="Enter Price" />
                              <small className="text-danger form-text">
                                {priceError}
                              </small>
                            </div>
                          </div>
                        </div>
                        : ''}
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Discount</label>
                          <div class="form_input">
                            <input type="text" value={discount} onChange={(e) => setDiscount(e.target.value)} class="form-control" placeholder="Enter Discount %" />
                          </div>
                        </div>
                      </div> </>}
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Plan Features <span className="text-danger">*</span></label>
                      <div class="form_input">
                        <Select
                          isMulti
                          placeholder="All Services"
                          onChange={setSelectedFeature}
                          value={selectedFeature}
                          options={featureData}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <small className="text-danger form-text">
                          {selectedFeatureError}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Description</label>
                      <div class="form_input">
                        <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} rows="4" placeholder="Enter Description"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div class="cn_t_add">
                    <button type="submit" class="btn btn_primary submitButton">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};


export default PlanAdd;
