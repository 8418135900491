import React, { useState, useEffect } from "react";
import sort from "../../assets/img/sort.svg";
import Pagination from '../../component/Pagination';
import { postDataService } from "../../component/userService";
import LoaderComponent from "../../component/LoaderComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let PageSize = 10;

const BookmarkedList = () => {
    const [Data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [sortBy, setSortBy] = useState('all');
    const [loading, setLoading] = useState(false);
    const [isData, setIsData] = useState(false);

    useEffect(() => {
        getData(currentPage, sortBy);
    }, [])

    async function getData(currentPage, sortBy) {
        var data = {
            currentPage: currentPage,
            PageSize: PageSize,
            sortBy: sortBy
        }
        setLoading(true);
        setIsData(false);
        await postDataService(`get_bookmarked_list`, new URLSearchParams(data))
            .then(response => {
                setLoading(false);
                if (response.status === 'success') {
                    setTotal(response.total);
                    setData(response.data);
                    setCurrentPage(currentPage);
                    if (response.data.length === 0) {
                        setIsData(true);
                    }
                } else {
                    toast.error(response.message);
                }
            });
    }

    function pageChange(page) {
        getData(page, sortBy);
    }

    function sortMember(sortBy) {
        setSortBy(sortBy);
        getData(currentPage, sortBy);
    }

    return (
        <>
            <div>
                <LoaderComponent active={loading} />
            </div>
            <section class="content_sec">
                <ToastContainer closeButton={true} position="top-right" />
                <div class="content_table">
                    <div class="content_table_itm card">
                        <div class="content_table_head">
                            <div class="content_table_head_cn">
                                <p>Bookmarked Code</p>
                                <p class="content_table_head_cn_prc">You have {total}</p>
                            </div>
                            <div class="content_table_head_sort">
                                <div class="cn_t_sort input-group select-group">
                                    <div class="cn_t_sort_ic input-group-addon"><img src={sort} /></div>
                                    <select class="form-control" onChange={(e) => sortMember(e.target.value)}>
                                        <option disabled>Sort</option>
                                        <option value="all">All</option>
                                        <option value="30">30 Days</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content_table_itm card">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th >S.N</th>
                                        <th>Code Name</th>
                                        <th>Category</th>
                                        <th>Technology</th>
                                        <th>OS</th>
                                        <th>Bookmarked By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Data.map((item, i) => {
                                        return (
                                            <tr>
                                                <td class="table_small_data">{(currentPage - 1) * PageSize + (i + 1)}</td>
                                                <td>{item.productName}</td>
                                                <td class="table_small_data">{item.categoryList.map((category, c) => {
                                                    return (
                                                        <span>{category.name}{item.categoryList.length === c + 1 ? '' : ', '}</span>
                                                    )
                                                })}</td>
                                                <td><span class="table_lable">Category</span> <span class="table_cont">{item.technologyList.map((technology, t) => {
                                                    return (
                                                        <span>{technology.name}{item.technologyList.length === t + 1 ? '' : ', '}</span>
                                                    )
                                                })}</span></td>
                                                <td><span class="table_lable">OS</span> <span class="table_cont">{item.osList.map((os, t) => {
                                                    return (
                                                        <span>{os.name}{item.osList.length === t + 1 ? '' : ', '}</span>
                                                    )
                                                })}</span></td>

                                                <td>{item.firstName} {item.lastName === 'undefined' ? '' : item.lastName}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {isData && (
                                <p className="text-center mt-3">Record Not Found!</p>
                            )}
                        </div>
                    </div>

                </div>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={total}
                    pageSize={PageSize}
                    onPageChange={page => pageChange(page)}
                />
            </section>
        </>
    );
};


export default BookmarkedList;
