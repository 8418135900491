import React, { useState, useEffect } from "react";
import sort from "../../assets/img/sort.svg";
import search from "../../assets/img/search.svg";
import edit from "../../assets/img/edit.svg";
import deleteNew from "../../assets/img/delete.svg";
import three_dots from "../../assets/img/three_dots.svg";
import Pagination from '../../component/Pagination';
import { postDataService } from "../../component/userService";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import LoaderComponent from "../../component/LoaderComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let PageSize = 10;

const MemberList = () => {
    const [Data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState('');
    const [sortBy, setSortBy] = useState('-1');
    const [loading, setLoading] = useState(false);
    const [isData, setIsData] = useState(false);

    useEffect(() => {
        if (filter === "") {
            getData(currentPage, filter, sortBy);
        }
    }, [filter])

    async function getData(currentPage, filter, sortBy) {
        var data = {
            currentPage: currentPage,
            PageSize: PageSize,
            filter: filter,
            sortBy: sortBy
        }
        setLoading(true);
        setIsData(false);
        await postDataService(`get_member`, new URLSearchParams(data))
            .then(response => {
                setLoading(false);
                if (response.status === 'success') {
                    setTotal(response.data.total);
                    setData(response.data.memberList);
                    setCurrentPage(currentPage);
                    if (response.data.memberList.length === 0) {
                        setIsData(true);
                    }
                } else {
                    toast.dismiss();
                    toast.error(response.message);
                }
            });
    }

    // Change Status
    async function changeStatus(_id, status) {
        var changeStatus = status;
        var galleryStatusData = {
            _id: _id,
            status: changeStatus
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await postDataService(`member_change_status`, new URLSearchParams(galleryStatusData))
                    .then(response => {
                        if (response.status === 'success') {
                            Swal.fire(
                                changeStatus + '!',
                                'Member' + changeStatus + 'Successfully!',
                                'success'
                            )
                            getData(currentPage, filter, sortBy);
                        } else {
                            Swal.fire(
                                changeStatus + '!',
                                'Unable To' + changeStatus + '!',
                                'error'
                            )
                        }
                    });
            }
        })

    }

    const handleFilterChange = e => {
        if (e.key === "Enter") {
            setCurrentPage(1);
            getData(1, filter, sortBy);
        }
    };

    // const handleFilterChange = (event) => {
    //     setFilter(event.target.value);
    //     setCurrentPage(1);
    //     getData(1, filter, sortBy);
    // };

    function pageChange(page) {
        getData(page, filter, sortBy);
    }

    function sortMember(sortBy) {
        setSortBy(sortBy);
        getData(currentPage, filter, sortBy);
    }

    return (
        <>
            <div>
                <LoaderComponent active={loading} />
            </div>
            <section class="content_sec">
                <ToastContainer closeButton={true} position="top-right" />
                <div class="content_table">
                    <div class="content_table_itm card">
                        <div class="content_table_head">
                            <div class="content_table_head_cn">
                                <p>Members</p>
                                <p class="content_table_head_cn_prc">You have {total}</p>
                            </div>
                            <div class="content_table_head_sort">
                                <div class="cn_t_sort input-group select-group">
                                    <div class="cn_t_sort_ic input-group-addon"><img src={sort} /></div>
                                    <select class="form-control" onChange={(e) => sortMember(e.target.value)}>
                                        <option>Sort</option>
                                        <option value="1">ASC</option>
                                        <option value="-1">DESC</option>
                                    </select>
                                </div>
                            </div>
                            <div class="content_table_head_search">
                                <div class="cn_t_search">
                                    <div class="marwal_r_search">
                                        <div class="marwal_search_ic"><img src={search} /></div>
                                        <input type="text" placeholder="Search here..." value={filter}
                                            onKeyPress={handleFilterChange} onChange={(e) => setFilter(e.target.value)} class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content_table_itm card">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="check_title">Name</th>
                                        <th>Joining Date</th>
                                        <th>Email</th>
                                        <th>Active Plan</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Data.map((item, i) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <div class="check_itm">
                                                        {/* <label class="custom_checkbox">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                        </label>  */}
                                                        {item.firstName} {item.lastName === 'undefined' ? '' : item.lastName}
                                                    </div>
                                                </td>
                                                <td class="table_small_data">{new Date(item.joiningDate).toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })}</td>


                                                <td><span class="table_lable">Email</span> <span class="table_cont">{item.email}</span></td>
                                                <td><span class="table_lable">Active Plan</span> <span class="table_cont">{item.enterprisePlan ? 'Enterprise Plan' : item.planInfo.map((plan, t) => {
                                                    return (
                                                        <span>{plan.name}{item.planInfo.length === t + 1 ? '' : ', '}</span>
                                                    )
                                                })}</span></td>


                                                <td class="action_td">
                                                    <div class="action_a"><img src={three_dots} /></div>
                                                    <div class="action_table">
                                                        <button className={item.status === 'Active' ? 'btn btn-status btn-danger' : "btn btn-status btn-success"} onClick={(e) => { changeStatus(item._id, item.status === 'Active' ? 'Deactive' : 'Active') }}>{item.status === 'Active' ? 'Deactive' : 'Re-activate'}</button>
                                                        <div class="action_table_itm"><Link to={"/members/edit/" + item._id}><img src={edit} /></Link></div>
                                                        <div class="action_table_itm"><button onClick={(e) => { changeStatus(item._id, 'Deleted') }}><img src={deleteNew} /></button></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {isData && (
                                <p className="text-center mt-3">Record Not Found!</p>
                            )}
                        </div>
                    </div>

                </div>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={total}
                    pageSize={PageSize}
                    onPageChange={page => pageChange(page)}
                />
            </section>
            {/* <section class="content_sec">
                <div class="content_table">
                    <div class="content_table_itm card">
                        <div class="content_table_head">
                            <div class="content_table_head_cn">
                                <p>Members</p>
                                <p class="content_table_head_cn_prc">You have 2,333</p>
                            </div>
                            <div class="content_table_head_meta">
                                <div class="cn_t_sort input-group select-group">
                                    <div class="cn_t_sort_ic input-group-addon"><img src={sort} /></div>
                                    <select class="form-control">
                                        <option>Sort</option>
                                        <option>Sort</option>
                                        <option>Sort</option>
                                        <option>Sort</option>
                                    </select>
                                </div>
                                <div class="cn_t_search">
                                    <div class="marwal_r_search">
                                        <div class="marwal_search_ic"><img src={search} /></div>
                                        <input type="text" placeholder="Search here..." class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content_table_itm card">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="check_title">Name</th>
                                        <th>Active Plan</th>
                                        <th>Joining Date</th>
                                        <th>Email</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="check_itm">
                                                <label class="custom_checkbox">
                                                    <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                </label> Nico Robin
                                            </div>
                                        </td>
                                        <td>Monthly - $99</td>
                                        <td>Aug 23, 2023</td>
                                        <td>female085@gmail.com</td>
                                        <td>
                                            <div class="action_table">
                                                <div class="action_table_itm"><a href="#"><img src={edit} /></a></div>
                                                <div class="action_table_itm"><a href="#"><img src={deleteNew} /></a></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="check_itm">
                                                <label class="custom_checkbox">
                                                    <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                </label> Nico Robin
                                            </div>
                                        </td>
                                        <td>Monthly - $99</td>
                                        <td>Aug 23, 2023</td>
                                        <td>female085@gmail.com</td>
                                        <td>
                                            <div class="action_table">
                                                <div class="action_table_itm"><a href="#"><img src={edit} /></a></div>
                                                <div class="action_table_itm"><a href="#"><img src={deleteNew} /></a></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="check_itm">
                                                <label class="custom_checkbox">
                                                    <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                </label> Nico Robin
                                            </div>
                                        </td>
                                        <td>Monthly - $99</td>
                                        <td>Aug 23, 2023</td>
                                        <td>female085@gmail.com</td>
                                        <td>
                                            <div class="action_table">
                                                <div class="action_table_itm"><a href="#"><img src={edit} /></a></div>
                                                <div class="action_table_itm"><a href="#"><img src={deleteNew} /></a></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="check_itm">
                                                <label class="custom_checkbox">
                                                    <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                </label> Nico Robin
                                            </div>
                                        </td>
                                        <td>Monthly - $99</td>
                                        <td>Aug 23, 2023</td>
                                        <td>female085@gmail.com</td>
                                        <td>
                                            <div class="action_table">
                                                <div class="action_table_itm"><a href="#"><img src={edit} /></a></div>
                                                <div class="action_table_itm"><a href="#"><img src={deleteNew} /></a></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label class="custom_checkbox">
                                                <input type="checkbox" checked="checked" />
                                                    <span class="checkmark"></span>
                                            </label> Nico Robin
                                        </td>
                                        <td>Monthly - $99</td>
                                        <td>Aug 23, 2023</td>
                                        <td>female085@gmail.com</td>
                                        <td>
                                            <div class="action_table">
                                                <div class="action_table_itm"><a href="#"><img src={edit} /></a></div>
                                                <div class="action_table_itm"><a href="#"><img src={deleteNew} /></a></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label class="custom_checkbox">
                                                <input type="checkbox" checked="checked" />
                                                    <span class="checkmark"></span>
                                            </label> Nico Robin
                                        </td>
                                        <td>Monthly - $99</td>
                                        <td>Aug 23, 2023</td>
                                        <td>female085@gmail.com</td>
                                        <td>
                                            <div class="action_table">
                                                <div class="action_table_itm"><a href="#"><img src={edit} /></a></div>
                                                <div class="action_table_itm"><a href="#"><img src={deleteNew} /></a></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label class="custom_checkbox">
                                                <input type="checkbox" checked="checked" />
                                                    <span class="checkmark"></span>
                                            </label> Nico Robin
                                        </td>
                                        <td>Monthly - $99</td>
                                        <td>Aug 23, 2023</td>
                                        <td>female085@gmail.com</td>
                                        <td>
                                            <div class="action_table">
                                                <div class="action_table_itm"><a href="#"><img src={edit} /></a></div>
                                                <div class="action_table_itm"><a href="#"><img src={deleteNew} /></a></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label class="custom_checkbox">
                                                <input type="checkbox" checked="checked" />
                                                    <span class="checkmark"></span>
                                            </label> Nico Robin
                                        </td>
                                        <td>Monthly - $99</td>
                                        <td>Aug 23, 2023</td>
                                        <td>female085@gmail.com</td>
                                        <td>
                                            <div class="action_table">
                                                <div class="action_table_itm"><a href="#"><img src={edit} /></a></div>
                                                <div class="action_table_itm"><a href="#"><img src={deleteNew} /></a></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </section> */}
        </>
    );
};


export default MemberList;
