import React, { useEffect, useState } from "react";
import upload from "../../assets/img/upload.svg";
import { getDataService, postDataService } from "../../component/userService";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate, useParams, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderComponent from "../../component/LoaderComponent";

const ProductEdit = () => {
  const navigate = useNavigate();
  var data = useParams();
  const [title, setTitle] = useState('');
  const [file, setFile] = useState('');
  // const [image, setImage] = useState('');
  const [categories, setCategories] = useState('');
  const [os, setOs] = useState('');
  const [technologies, setTechnologies] = useState('');
  const [json, setJSON] = useState('');
  const [zipFile, setZipFile] = useState('');
  const [extractFile, setExtractFile] = useState('');
  // const [description, setDescription] = useState('');
  // const [shortDescription, setShortDescription] = useState();
  const [type, setType] = useState();
  // const [imagePath, setImagePath] = useState('');

  const [titleError, setTitleError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [fileError, setFileError] = useState('');
  // const [descriptionError, setDescriptionError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      getIndividualData();
    }
  }, [])

  async function getIndividualData() {
    await getDataService(`get_individual_product/${data.id}`)
      .then(response => {
        if (response.status === 'success') {
          setTitle(response.data[0].title);
          // setDescription(response.data[0].description);
          setType(response.data[0].type);
          // setShortDescription(response.data[0].shortDescription);
          // setImagePath(response.data[0].image);
        } else {
          toast.error(response.message);
        }
      });
  }

  // Validation
  const handleValidation = (event) => {
    let formIsValid = false;
    if (!title || title.trim() === '') {
      setTitleError("Title is required!");
      return false;
    } else {
      setTitleError("");
      formIsValid = true;
    }
    if (!type) {
      setTypeError("Type is required!");
      return false;
    } else {
      setTypeError("");
      formIsValid = true;
    }
    if (Object.keys(data).length == 0) {
      if (!file) {
        setFileError("File is required!");
        return false;
      } else {
        setFileError("");
        formIsValid = true;
      }
    }
    // if (!description) {
    //   setDescriptionError("Description is required!");
    //   return false;
    // } else {
    //   setDescriptionError("");
    //   formIsValid = true;
    // }
    return formIsValid;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (handleValidation()) {
      if (Object.keys(data).length !== 0) {

        const formData = {
          "_id": data.id,
          'title': title,
          'categories': categories,
          'os': os,
          'technologies': technologies,
          // 'description': description,
          // "shortDescription": shortDescription,
          "type": type,
          'json': JSON.stringify(json),
          'zipFile': zipFile,
          'extractFile': extractFile,
          'userId': window.localStorage.getItem('userId')
        }
        setLoading(true);
        await postDataService(`update_product`, new URLSearchParams(formData))
          .then(response => {
            setLoading(false);
            if (response.status === 'success') {
              toast.success(response.message);
              setTimeout(() => {
                navigate("/source-code");
              }, 1000);
            } else {
              toast.error(response.message);
            }
          });

      } else {

        const formData = {
          'title': title,
          'categories': categories,
          'os': os,
          'technologies': technologies,
          // 'description': description,
          // "shortDescription": shortDescription,
          "type": type,
          'json': JSON.stringify(json),
          'zipFile': zipFile,
          'extractFile': extractFile,
          'userId': window.localStorage.getItem('userId')
        }
        setLoading(true);
        await postDataService(`add_product`, new URLSearchParams(formData))
          .then(response => {
            setLoading(false);
            if (response.status === 'success') {
              toast.success(response.message);
              setTimeout(() => {
                navigate("/source-code");
              }, 1000);
            } else {
              toast.error(response.message);
            }
          });
      }

    }
  }

  async function onSelectFile(file) {
    setFile(file);
    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);
    await postDataService(`upload_product_file`, formData)
      .then(async response => {
        if (response.status === 'success') {
          if (response.data.json.Technologies === undefined || response.data.json.Technologies === '') {
            var getTechnology = {
              extension: response.data.getTechnology
            }
            await postDataService(`get_project_prefix_technology`, new URLSearchParams(getTechnology))
              .then(responseTechnology => {
                setLoading(false);
                if (response.status === 'success') {
                  setTitle(response.data.zipFileName);
                  setJSON(response.data.json);
                  setZipFile(response.data.zipFile);
                  setExtractFile(response.data.extractFile);
                  setCategories(response.data.json.Category === undefined ? '' : response.data.json.Category);
                  setOs(response.data.json.OS === undefined ? '' : response.data.json.OS);
                  setTechnologies(responseTechnology.data === undefined ? '' : responseTechnology.data);
                  // toast.success(response.message);
                } else {
                  setFile('');
                  toast.error(response.message);
                }
              });
          } else {
            setTitle(response.data.zipFileName);
            setJSON(response.data.json);
            setZipFile(response.data.zipFile);
            setExtractFile(response.data.extractFile);
            setCategories(response.data.json.Category === undefined ? '' : response.data.json.Category);
            setOs(response.data.json.OS === undefined ? '' : response.data.json.OS);
            setTechnologies(response.data.json.Technologies === undefined ? '' : response.data.json.Technologies);
          }
          // toast.success(response.message);
        } else {
          setFile('');
          toast.error(response.message);
        }
      });
    setLoading(false);
  }

  return (
    <>
      <div>
        <LoaderComponent active={loading} />
      </div>
      <section class="content_sec">
        <ToastContainer closeButton={true} position="top-right" />
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div class="content_table">
            <div class="card card_editor">
              <div class="card_editor_in">
                <div class="card_editor_meta">
                  <div class="card_editor_s">Title <span className="text-danger">*</span></div>
                  <div class="card_editor_btns">
                    <div class="card_editor_btn"><button class="btn btn_secondary"><Link to="/source-code">Cancel</Link> </button></div>
                    <div class="card_editor_btn"><button class="btn btn_primary" type="submit">Save</button></div>
                  </div>
                </div>
                <div class="card_editor_input">
                  <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter source name" class="form-control" />
                  <small className="text-danger form-text">
                    {titleError}
                  </small>
                </div>
              </div>
            </div>

            <div class="card upload_crad">
              <div class="upload_crad_in">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Type <span className="text-danger">*</span></label>
                      <select class="form-control" value={type} onChange={(e) => setType(e.target.value)}>
                        <option>Select Type</option>
                        <option value="project">Project</option>
                        <option value="sourceCode">Source Code</option>
                      </select>
                      <small className="text-danger form-text">
                        {typeError}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="content_table">
            <div class="content_table_card card">
            </div>
            <div class="card drag_dropfile">
              <div class="drag_dropfile_in">
                <div class="drag_dropfile_cn">
                  <div class="upload_ic"><img src={upload} /></div>
                  <h6>Drag & Drop file you want to upload</h6>
                  <p>{zipFile}</p>
                  <button class="btn btn_primary"><input accept=".zip" onChange={(e) => onSelectFile(e.target.files[0])} type="file" />Browser</button>
                  <small className="text-danger form-text">
                    {fileError}
                  </small>
                </div>
              </div>
            </div>
            <div class="card upload_crad">
              <div class="upload_crad_in">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Technologies</label>
                      <input type="text" value={technologies} class="form-control" readOnly placeholder="Technologies" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Categories</label>
                      <input value={categories} type="text" readOnly placeholder="Categories" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>OS</label>
                      <input class="form-control" value={os} type="text" readOnly placeholder="OS" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default ProductEdit;
