import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeViewer = ({ code }) => {
    const codeString = '(num) => num + 1';
  return (
    <SyntaxHighlighter language="javascript" style={a11yDark} showLineNumbers>
      {code}
    </SyntaxHighlighter>
  );
};

export default CodeViewer;