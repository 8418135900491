import React, { useState } from "react";
import icons from "../../assets/img/icons.png";
import content from "../../assets/img/content.png";

import upload from "../../assets/img/upload.svg";
import codeing from "../../assets/img/codeing.svg";
import cross from "../../assets/img/cross.svg";
import email from "../../assets/img/email.svg";
import { postDataService } from "../../component/userService";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ProductEdit = () => {
  const [description, setDescription] = useState()

  async function onSelectFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    await postDataService(`upload_product_file`, formData)
      .then(response => {
        if (response.status === 'success') {
        } else {
        }
      });
  }
  return (
    <>
      <section class="content_sec">
        <div class="content_table">
          <div class="card card_editor">
            <div class="card_editor_in">
              <div class="card_editor_meta">
                <div class="card_editor_s"><img src={icons} /></div>
                <div class="card_editor_btns">
                  <div class="card_editor_btn"><button class="btn btn_secondary">Cancel</button></div>
                  <div class="card_editor_btn"><button class="btn btn_primary">Save</button></div>
                </div>
              </div>
              <div class="card_editor_input">
                <input type="text" name="" value="The Ultimate Guide how to start an Agency" class="form-control" />
              </div>
            </div>
          </div>
          <div class="card card_editor">
            <div class="card_editor_con">
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onInit={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => { setDescription(editor.getData()) }}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor);
                }}
              />
              <img src={content} />
            </div>
          </div>
        </div>
      </section>
      <section class="content_sec">
        <div class="content_table">
          <form>
            <div class="content_table_card card">
              <div class="content_table_head">
                <div class="content_table_head_cn">
                  <p>Bulk Update</p>
                  <p class="content_table_head_cn_prc">Now you can upload source file in bulk</p>
                </div>
                <div class="cn_t_add">
                  <button type="button" class="btn btn_primary">Upload</button>
                </div>
              </div>
            </div>
            <div class="card drag_dropfile">
              <div class="drag_dropfile_in">
                <input type="file" multiple />
                <div class="drag_dropfile_cn">
                  <div class="upload_ic"><img src={upload} /></div>
                  <h6>Drag & Drop file you want to upload</h6>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>

                  <button class="btn btn_primary"><input multiple accept=".zip" onChange={(e) => onSelectFile(e.target.files[0])} type="file" />Browser</button>
                </div>
              </div>
            </div>
            <div class="card upload_crad">
              <div class="upload_crad_in">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Title</label>
                      <div class="form_input">
                        <div class="form_input_ic">
                          <img src={email} />
                        </div>
                        <input type="text" name="" class="form-control" placeholder="Enter title of the file..." />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Category</label>
                      <select class="form-control">
                        <option>Select category</option>
                        <option>Select category</option>
                        <option>Select category</option>
                        <option>Select category</option>
                        <option>Select category</option>
                        <option>Select category</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Sub-Category</label>
                      <select class="form-control">
                        <option>Select Sub-Category</option>
                        <option>Select Sub-Category</option>
                        <option>Select Sub-Category</option>
                        <option>Select Sub-Category</option>
                        <option>Select Sub-Category</option>
                        <option>Select Sub-Category</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group mb-0">
                      <div class="form_card">
                        <div class="content_card_ic"><img src={codeing} /></div>
                        <div class="form_card_con">
                          <div class="form_card_in">
                            <h5>Blitstect Ninja UXUI Agency 01.mp4</h5>
                            <ul>
                              <li>20 MB</li>
                              <li>1000 Lines</li>
                            </ul>
                          </div>
                          <div class="form_card_process">
                            <div class="form_card_process_cross"><img src={cross} /></div>
                            <div class="progress_cc">
                              <div class="progress">
                                <div class="progress-bar" style={{ "width": "70%" }}></div>
                              </div>
                              <div class="progress-bar-con">70%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card upload_crad">
              <div class="upload_crad_in">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Title</label>
                      <div class="form_input">
                        <div class="form_input_ic">
                          <img src={email} />
                        </div>
                        <input type="text" name="" class="form-control" placeholder="Enter title of the file..." />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Category</label>
                      <select class="form-control">
                        <option>Select category</option>
                        <option>Select category</option>
                        <option>Select category</option>
                        <option>Select category</option>
                        <option>Select category</option>
                        <option>Select category</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Sub-Category</label>
                      <select class="form-control">
                        <option>Select Sub-Category</option>
                        <option>Select Sub-Category</option>
                        <option>Select Sub-Category</option>
                        <option>Select Sub-Category</option>
                        <option>Select Sub-Category</option>
                        <option>Select Sub-Category</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group mb-0">
                      <div class="form_card">
                        <div class="content_card_ic"><img src={codeing} /></div>
                        <div class="form_card_con">
                          <div class="form_card_in">
                            <h5>Blitstect Ninja UXUI Agency 01.mp4</h5>
                            <ul>
                              <li>20 MB</li>
                              <li>1000 Lines</li>
                            </ul>
                          </div>
                          <div class="progress_cc">
                            <div class="progress">
                              <div class="progress-bar" style={{ "width": "70%" }}></div>
                            </div>
                            <div class="progress-bar-con">70%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};


export default ProductEdit;
