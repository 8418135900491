import React, { useState, useEffect } from "react";
import { getDataService, postDataService } from "../../component/userService";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OperatingSystemEdit = () => {
  const history = useNavigate();
  var data = useParams();
  const [name, setName] = useState();
  const [status, setStatus] = useState();

  const [nameError, setNameError] = useState();
  const [statusError, setStatusError] = useState();

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      getIndividualData();
    }
  }, [])

  async function getIndividualData() {
    await getDataService(`get_individual_operating_system/${data.id}`)
      .then(response => {
        if (response.status === 'success') {
          setName(response.data.name);
          setStatus(response.data.status);
        } else {
          console.log(response.message);
        }
      });
  }

  // Validation
  const handleValidation = (event) => {
    let formIsValid = false;
    if (!name) {
      setNameError("Name is required!");
      return false;
    } else {
      setNameError("");
      formIsValid = true;
    }

    if (!status) {
      setStatusError("Status is required!");
      return false;
    } else {
      setStatusError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (handleValidation()) {
      if (Object.keys(data).length !== 0) {
        var featureData = {
          name: name,
          status: status,
          _id: data.id
        }

        await postDataService(`update_operating_system`, new URLSearchParams(featureData))
          .then(response => {
            if (response.status === 'success') {
              toast.success(response.message);
              setTimeout(() => {
                history("/operating-system");
              }, 3000);
            } else {
              toast.error(response.message);
            }
          });

      }

    }
  }

  return (
    <>
      <section class="content_sec">
        <ToastContainer closeButton={true} position="top-right" />
        <div class="content_table">
          <div class="content_table_card card">
            <div class="content_table_head">
              <div class="content_table_head_cn">
                <p>Edit Technology</p>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div class="card upload_crad">
              <div class="upload_crad_in">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Name <span className="text-danger">*</span></label>
                      <div class="form_input">
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} class="form-control" placeholder="Enter Name" />
                        <small className="text-danger form-text">
                          {nameError}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Status <span className="text-danger">*</span></label>
                      <select class="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option>Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                      <small className="text-danger form-text">
                        {statusError}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div class="cn_t_add">
                    <button type="submit" class="btn btn_primary submitButton">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};


export default OperatingSystemEdit;
