import React, { useEffect, useState } from "react";
import { DiCss3, DiHtml5, DiJavascript, DiNpm, DiPhp } from "react-icons/di";
import { FaImage, FaInfo, FaReadme, FaVideo } from 'react-icons/fa';
import { FaList, FaRegFolder, FaRegFolderOpen } from "react-icons/fa";
import TreeView, { flattenTree } from "react-accessible-treeview";
import "../assets/css/styleTree.css";
import { getDataService, postDataService, serverLink } from "../component/userService";
import { useParams } from "react-router-dom";
import CodeViewer from "./CodeViewer";
import LoaderComponent from "../component/LoaderComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MultiSelectDirectoryTreeView = () => {
  var params = useParams();
  const [fileCode, setFileCode] = useState('No File Selected!');
  const [fileLine, setFileLine] = useState(0);
  const [dataNew, setDataNew] = useState();
  const [title, setTitle] = useState();

  // const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [technologyList, setTechnologyList] = useState([]);
  const [osList, setOsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFile, setIsFile] = useState('file');

  useEffect(() => {
    getData();
  }, [])

  // Get Feature List
  async function getData() {
    try {
      await getDataService(`get_product_details/${params.productId}`)
        .then(response => {
          if (response.status === 'success') {
            setBreadcrumbs(response.data.projectDetail.title);
            setTitle(response.data.projectDetail.title);
            setCategoryList(response.data.projectDetail.categoryList);
            setTechnologyList(response.data.projectDetail.technologyList);
            setOsList(response.data.projectDetail.osList);

            const folder = {
              name: "",
              children: response.data.allFiles,
            };

            const data = flattenTree(folder);
            setDataNew(<TreeView
              data={data}
              aria-label="directory tree"
              togglableSelect
              clickAction="EXCLUSIVE_SELECT"
              multiSelect
              nodeRenderer={({
                element,
                isBranch,
                isExpanded,
                getNodeProps,
                level,
                handleSelect,
              }) => (
                <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
                  {isBranch || element.metadata.type === 'folder' ? (
                    <>
                      <FolderIcon isOpen={isExpanded} />
                      <span>{element.name}</span>
                    </>
                  ) : (
                    <>
                      <FileIcon filename={element.name} />
                      <span onClick={() => openFile(element.metadata.path, element, element.name)}>{element.name}</span>
                    </>
                  )}
                </div>
              )}
            />);
          } else {
            toast.dismiss();
            toast.error(response.message);
            console.log(response.message);
          }
        });
    } catch (error) {
      console.log(error);
    }

  }

  async function openFile(filePath, element, fileName) {
    var pathLink = filePath;
    const pathSegments = pathLink.split("uploads");
    setBreadcrumbs(fileName);
    const extension = fileName.slice(fileName.lastIndexOf(".") + 1).toLowerCase();
    if (extension === 'png' || extension === 'jpg' || extension === 'jpeg' || extension === 'tif' || extension === 'tiff' || extension === 'gif') {
      setIsFile('image');
      setFileCode(serverLink + '/resources/uploads' + pathSegments[1]);
    } else if (extension === 'pdf') {
      setIsFile('pdf');
      setFileCode(serverLink + '/resources/uploads' + pathSegments[1] + '#toolbar=0&navpanes=0&scrollbar=0');
    } else {
      setIsFile('file');
      var data = {
        path: filePath
      }
      // Get File Data
      toast.dismiss();
      setLoading(true);
      await postDataService(`get_file_code`, new URLSearchParams(data))
        .then(response => {
          setLoading(false);
          if (response.status === 'success') {
            setFileCode(response.data.code);
            setFileLine(response.data.lines);
          } else {
            toast.error(response.message);
            console.log(response.message);
          }
        });
    }
  }

  const FolderIcon = ({ isOpen }) =>
    isOpen ? (
      <FaRegFolderOpen color="e8a87c" className="icon" />
    ) : (
      <FaRegFolder color="e8a87c" className="icon" />
    );

  const FileIcon = ({ filename }) => {
    const extension = filename.slice(filename.lastIndexOf(".") + 1).toLowerCase();
    switch (extension) {
      case "js":
        return <DiJavascript color="yellow" className="icon" />;
      case "php":
        return <DiPhp color="blue" className="icon" />;
      case "jsx":
        return <DiJavascript color="yellow" className="icon" />;
      case "css":
        return <DiCss3 color="turquoise" className="icon" />;
      case "json":
        return <FaList color="yellow" className="icon" />;
      case "npmignore":
        return <DiNpm color="red" className="icon" />;
      case "html":
        return <DiHtml5 color="yellow" className="icon" />;
      case "png":
        return <FaImage color="blue" className="icon" />;
      case "jpg":
        return <FaImage color="blue" className="icon" />;
      case "jpeg":
        return <FaImage color="blue" className="icon" />;
      case "gif":
        return <FaVideo color="blue" className="icon" />;
      case "tif":
        return <FaImage color="blue" className="icon" />;
      case "tiff":
        return <FaImage color="blue" className="icon" />;
      case "md":
        return <FaInfo color="blue" className="icon" />;
      default:
        return null;
    }
  };


  return (
    <>
      <div>
        <LoaderComponent active={loading} />
      </div>
      <div className="code_nav content_cards">
        <ToastContainer closeButton={true} position="top-right" />
        <div className="code_nav_itm">
          <div>
            {breadcrumbs}
          </div>
        </div>
        <div className="code_nav_itm">{categoryList.map((category, c) => {
          return (
            <span>{category.name}{categoryList.length === c + 1 ? '' : ', '}</span>
          )
        })}
        </div>
        <div className="code_nav_itm">
          {osList.map((os, t) => {
            return (
              <span>{os.name}{osList.length === t + 1 ? '' : ', '}</span>
            )
          })}
        </div>
        <div className="code_nav_itm">
          {technologyList.map((technology, t) => {
            return (
              <span>{technology.name}{technologyList.length === t + 1 ? '' : ', '}</span>
            )
          })}
        </div>

        <div className="code_nav_itm">
          <span>
            {fileLine} lines
          </span>
        </div>

      </div>
      <div className="code_section">
        <div className="code_section_sidebar">
          <div className="ide">
            {dataNew}
          </div>
        </div>
        <div className="code_section_content">
          {isFile === 'image' ? <img src={fileCode} /> : isFile === 'pdf' ? <embed src={fileCode} width="100%" height="100%" /> : <CodeViewer code={fileCode} />}
        </div>
      </div>
    </>
  );
}


export default MultiSelectDirectoryTreeView;