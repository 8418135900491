import React from 'react';
import CodeViewer from './CodeViewer'; // Adjust the path accordingly

const FileView = () => {
  const code = `const greeting = "Hello, World!";
  console.log(greeting);`;

  return (
    <div>
      <h1>Code Viewer</h1>
      <CodeViewer code={code} />
    </div>
  );
};

export default FileView;