import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Protected from './component/Protected'
import Dashboard from './pages/Dashboard';
import PrivateRoute from './component/PrivateRoute';
import Login from './pages/Login';
import ProductList from './pages/Product/List';
import MemberList from './pages/Member/List';
import ProductEdit from './pages/Product/Edit';
import SourceCodeForm from './pages/Product/Form';
import PlanList from './pages/Plan/List';
import PlanAdd from './pages/Plan/Add';
import FeatureList from './pages/Feature/List';
import FeatureAdd from './pages/Feature/Add';
import MemberEdit from './pages/Member/Edit';
import CategoryList from './pages/Category/List';
import CategoryEdit from './pages/Category/Edit';
import TechnologyList from './pages/Technology/List';
import TechnologyEdit from './pages/Technology/Edit';
import OSList from './pages/OperatingSystem/List';
import OperatingSystemEdit from './pages/OperatingSystem/Edit';
import FileManager from './pages/fileManager';
import ReportedList from './pages/Reported/List';
import BookmarkedList from './pages/BookMark/List';
import DownloadedList from './pages/Downloaded/List';
import FileView from './pages/fileView';
import CopiedList from './pages/CopyCode/List';
import Enable2fa from './pages/Enable-2fa';
import LoginTwoFactor from './pages/LoginTwoFactor';

function App() {
  const [isLogged, setIsLogged] = useState(true);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/login-with-otp" element={<LoginTwoFactor />} exact />
        <Route exact path="/file-view" element={<FileView />} />
        {/* File Manager */}

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path="/dashboard" element={<Protected isSignedIn={isLogged}><Dashboard /></Protected>} />
          {/* Source Code */}
          <Route exact path="/source-code" element={<Protected isSignedIn={isLogged}><ProductList /></Protected>} />
          <Route exact path="/source-code/edit" element={<Protected isSignedIn={isLogged}><ProductEdit /></Protected>} />
          <Route exact path="/source-code/add" element={<Protected isSignedIn={isLogged}><SourceCodeForm /></Protected>} />
          <Route exact path="/source-code/edit/:id" element={<Protected isSignedIn={isLogged}><SourceCodeForm /></Protected>} />
          <Route exact path="/source-code/:productId" element={<Protected isSignedIn={isLogged}><FileManager /></Protected>} />
          {/* Member */}
          <Route exact path="/members" element={<Protected isSignedIn={isLogged}><MemberList /></Protected>} />
          <Route exact path="/members/edit/:id" element={<Protected isSignedIn={isLogged}><MemberEdit /></Protected>} />
          {/* Category */}
          <Route exact path="/malware-type" element={<Protected isSignedIn={isLogged}><CategoryList /></Protected>} />
          <Route exact path="/malware-type/edit/:id" element={<Protected isSignedIn={isLogged}><CategoryEdit /></Protected>} />
          {/* Technology */}
          <Route exact path="/technology" element={<Protected isSignedIn={isLogged}><TechnologyList /></Protected>} />
          <Route exact path="/technology/edit/:id" element={<Protected isSignedIn={isLogged}><TechnologyEdit /></Protected>} />
          {/* Operating System */}
          <Route exact path="/operating-system" element={<Protected isSignedIn={isLogged}><OSList /></Protected>} />
          <Route exact path="/operating-system/edit/:id" element={<Protected isSignedIn={isLogged}><OperatingSystemEdit /></Protected>} />
          {/* Plan */}
          <Route exact path="/plan" element={<Protected isSignedIn={isLogged}><PlanList /></Protected>} />
          <Route exact path="/plan/add" element={<Protected isSignedIn={isLogged}><PlanAdd /></Protected>} />
          <Route exact path="/plan/edit/:id" element={<Protected isSignedIn={isLogged}><PlanAdd /></Protected>} />
          {/* Feature */}
          <Route exact path="/feature" element={<Protected isSignedIn={isLogged}><FeatureList /></Protected>} />
          <Route exact path="/feature/add" element={<Protected isSignedIn={isLogged}><FeatureAdd /></Protected>} />
          <Route exact path="/feature/edit/:id" element={<Protected isSignedIn={isLogged}><FeatureAdd /></Protected>} />
          {/* Reported */}
          <Route exact path="/reported-code" element={<Protected isSignedIn={isLogged}><ReportedList /></Protected>} />
          {/* Bookmarked */}
          <Route exact path="/bookmarked-code" element={<Protected isSignedIn={isLogged}><BookmarkedList /></Protected>} />
          {/* Downloaded */}
          <Route exact path="/downloaded-code" element={<Protected isSignedIn={isLogged}><DownloadedList /></Protected>} />
          {/* COPIED CODE */}
          <Route exact path="/copied-code" element={<Protected isSignedIn={isLogged}><CopiedList /></Protected>} />
          {/* Enable 2-FA */}
          <Route exact path="/enable-two-factor" element={<Protected isSignedIn={isLogged}><Enable2fa /></Protected>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
