import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import user1 from "../assets/img/user1.svg";
import graph1 from "../assets/img/graph1.svg";
import codeing from "../assets/img/codeing.svg";
import active_user from "../assets/img/active_user.svg";
import revenue from "../assets/img/revenue.svg";
import sort from "../assets/img/sort.svg";
import search from "../assets/img/search.svg";
import edit from "../assets/img/edit.svg";
import deleteNew from "../assets/img/delete.svg";
import three_dots from "../assets/img/three_dots.svg";
import { postDataService } from "../component/userService";
import ic4 from "../assets/img/ic4.svg";
import Swal from "sweetalert2";
import LoaderComponent from "../component/LoaderComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let PageSize = 10;

const Dashboard = () => {

  const [memberData, setMemberData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [totalMember, setTotalMember] = useState(0);
  const [totalActiveMember, setTotalActiveMember] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [filterMember, setFilterMember] = useState('');
  const [filterProduct, setFilterProduct] = useState('');
  const [sortByMember, setSortByMember] = useState(-1);
  const [sortByProduct, setSortByProduct] = useState(-1);
  const [totalRevenue, setTotalRevenue] = useState();
  const [totalPremium, setTotalPremium] = useState();
  const [totalEnterprise, setTotalEnterprise] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (filterMember === "") {
      getData(filterMember, filterProduct, sortByMember, sortByProduct);
    }
  }, [filterMember])

  useEffect(() => {
    if (filterProduct === "") {
      getData(filterMember, filterProduct, sortByMember, sortByProduct);
    }
  }, [filterProduct])

  async function getData(filterMember, filterProduct, sortByMember, sortByProduct) {

    var data = {
      memberFilter: filterMember,
      sourceCodeFilter: filterProduct,
      sortByMember: sortByMember,
      sortByProduct: sortByProduct
    }
    setLoading(true);
    await postDataService(`get_dashboard`, new URLSearchParams(data))
      .then(response => {
        setLoading(false);
        if (response.status === 'success') {
          setTotalMember(response.data.member.totalMember);
          setTotalActiveMember(response.data.member.totalActiveMember);
          setMemberData(response.data.member.memberList);

          setTotalProduct(response.data.product.totalMember);
          setProductData(response.data.product.productList);
          setTotalPremium(response.data.totalPremium);
          setTotalEnterprise(response.data.totalEnterprise);
          setTotalRevenue(response.data.totalRevenue);
        } else {
          toast.error(response.message);
        }
      });
  }

  // Change Status Member
  async function changeStatus(_id, status) {
    var changeStatus = status;
    var galleryStatusData = {
      _id: _id,
      status: changeStatus
    }
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await postDataService(`member_change_status`, new URLSearchParams(galleryStatusData))
          .then(response => {
            if (response.status === 'success') {
              Swal.fire(
                changeStatus + '!',
                'Member Deleted Successfully!',
                'success'
              )
              getData(filterMember, filterProduct, sortByMember, sortByProduct);
            } else {
              Swal.fire(
                changeStatus + '!',
                'Unable To Delete!',
                'error'
              )
            }
          });
      }
    })

  }

  // Change Status Product / source Code
  async function changeStatusProduct(_id, status) {
    var changeStatus = status;
    var galleryStatusData = {
      _id: _id,
      status: changeStatus
    }
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await postDataService(`product_change_status`, new URLSearchParams(galleryStatusData))
          .then(response => {
            if (response.status === 'success') {
              Swal.fire(
                changeStatus + '!',
                'Source Code Deleted Successfully!',
                'success'
              )
              getData(filterMember, filterProduct, sortByMember, sortByProduct);
            } else {
              Swal.fire(
                changeStatus + '!',
                'Unable To Delete!',
                'error'
              )
            }
          });
      }
    })

  }

  const handleFilterChange = e => {
    if (e.key === "Enter") {
      getData(filterMember, filterProduct, sortByMember, sortByProduct);
    }
  };

  const handleFilterChangeProduct = e => {
    if (e.key === "Enter") {
      getData(filterMember, filterProduct, sortByMember, sortByProduct);
    }
  };

  function sortMember(sortByMember) {
    setSortByMember(sortByMember);
    getData(filterMember, filterProduct, sortByMember, sortByProduct);
  }

  function sortProduct(sortByProduct) {
    setSortByProduct(sortByProduct);
    getData(filterMember, filterProduct, sortByMember, sortByProduct);
  }

  return (
    <>
      <div>
        <LoaderComponent active={loading} />
      </div>
      <section className="content_sec">
        <ToastContainer closeButton={true} position="top-right" />
        <div className="content_cards">
          <div className="row">
            <div className="col-xxl-4 col-xl-12 col-6">
              <div className="content_card card">
                <div className="content_card_ic"><img src={user1} /></div>
                <div className="content_card_con">
                  <div className="content_card_in">
                    <h5>{totalMember}</h5>
                    <p>Total Members</p>
                  </div>
                  <div className="content_card_img"><img src={graph1} /></div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-6">
              <div className="content_card card">
                <div className="content_card_ic"><img src={codeing} /></div>
                <div className="content_card_con">
                  <div className="content_card_in">
                    <h5>{totalProduct}</h5>
                    <p>Total Source Code</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-6">
              <div className="content_card card">
                <div className="content_card_ic"><img src={active_user} /></div>
                <div className="content_card_con">
                  <div className="content_card_in">
                    <h5>{totalActiveMember}</h5>
                    <p>Total Active Member</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-6">
              <div className="content_card card">
                <div className="content_card_ic"><img src={ic4} /></div>
                <div className="content_card_con">
                  <div className="content_card_in">
                    <h5>{totalEnterprise}</h5>
                    <p>Total Enterprise Plans</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-6">
              <div className="content_card card">
                <div className="content_card_ic"><img src={ic4} /></div>
                <div className="content_card_con">
                  <div className="content_card_in">
                    <h5>{totalPremium}</h5>
                    <p>Total Premium Plans</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content_table">
          <div className="content_table_itm card">
            <div className="content_table_head">
              <div className="content_table_head_cn">
                <p>Members</p>
                <p className="content_table_head_cn_prc">You have {totalMember}</p>
              </div>
              <div className="content_table_head_sort">
                <div className="cn_t_sort input-group select-group">
                  <div className="cn_t_sort_ic input-group-addon"><img src={sort} /></div>
                  <select className="form-control" onChange={(e) => sortMember(e.target.value)}>
                    <option>Sort</option>
                    <option value="1">ASC</option>
                    <option value="-1">DESC</option>
                  </select>
                </div>
              </div>
              <div className="content_table_head_search">
                <div className="cn_t_search">
                  <div className="marwal_r_search">
                    <div className="marwal_search_ic"><img src={search} /></div>
                    <input type="text" placeholder="Search here..." value={filterMember}
                      onKeyPress={handleFilterChange} onChange={(e) => setFilterMember(e.target.value)} className="form-control" />
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="check_title">Name</th>
                    <th>Joining Date</th>
                    <th>Email</th>
                    <th>Active Plan</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {memberData.map((item, i) => {
                    return (
                      <tr>
                        <td>
                          <div className="check_itm">
                            {item.firstName} {item.lastName === 'undefined' ? '' : item.lastName}
                          </div>
                        </td>
                        <td className="table_small_data">{new Date(item.joiningDate).toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })}</td>
                        <td><span className="table_lable">Email</span> <span className="table_cont">{item.email}</span></td>
                        <td><span className="table_lable">Active Plan</span> <span className="table_cont">{item.enterprisePlan ? 'Enterprise Plan' : item.planInfo.map((plan, t) => {
                          return (
                            <span>{plan.name}{item.planInfo.length === t + 1 ? '' : ', '}</span>
                          )
                        })}</span></td>
                        <td className="action_td">
                          <div className="action_a"><img src={three_dots} /></div>
                          <div className="action_table">
                            <div className="action_table_itm"><Link to={"/members/edit/" + item._id}><img src={edit} /></Link></div>
                            <div className="action_table_itm"><button onClick={(e) => { changeStatus(item._id, 'Deleted') }}><img src={deleteNew} /></button></div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>
              {memberData.length === 0 && (
                <p className="text-center mt-3">Record Not Found!</p>
              )}
            </div>
          </div>
          <div className="content_table_itm card">
            <div className="content_table_head">
              <div className="content_table_head_cn">
                <p>Source Code</p>
                <p className="content_table_head_cn_prc">You have {totalProduct}</p>
              </div>
              <div className="content_table_head_sort">
                <div className="cn_t_sort input-group select-group">
                  <div className="cn_t_sort_ic input-group-addon"><img src={sort} /></div>
                  <select className="form-control" onChange={(e) => sortProduct(e.target.value)}>
                    <option>Sort</option>
                    <option value="1">ASC</option>
                    <option value="-1">DESC</option>
                  </select>
                </div>
              </div>
              <div className="content_table_head_search">
                <div className="cn_t_search">
                  <div className="marwal_r_search">
                    <div className="marwal_search_ic"><img src={search} /></div>
                    <input type="text" placeholder="Search here..." value={filterProduct}
                      onKeyPress={handleFilterChangeProduct} onChange={(e) => setFilterProduct(e.target.value)} className="form-control" />
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Code Name</th>
                    <th>Technology</th>
                    <th>Category</th>
                    <th>OS</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {productData.map((item, i) => {
                    return (
                      <tr>
                        <td>{item.title}</td>
                        <td className="table_small_data">{item.technologyList.map((technology, t) => {
                          return (
                            <span>{technology.name}{item.technologyList.length === t + 1 ? '' : ', '}</span>
                          )
                        })}</td>
                        <td><span className="table_lable">Category</span> <span className="table_cont">{item.categoryList.map((category, c) => {
                          return (
                            <span>{category.name}{item.categoryList.length === c + 1 ? '' : ', '}</span>
                          )
                        })}</span></td>
                        <td><span className="table_lable">OS</span> <span className="table_cont">{item.osList.map((os, t) => {
                          return (
                            <span>{os.name}{item.osList.length === t + 1 ? '' : ', '}</span>
                          )
                        })}</span></td>
                        <td className="action_td">
                          <div className="action_a"><img src={three_dots} /></div>
                          <div className="action_table">
                            <div className="action_table_itm"><Link to={"/source-code/edit/" + item._id}><img src={edit} /></Link></div>
                            <div className="action_table_itm"><button onClick={(e) => { changeStatusProduct(item._id, 'Deleted') }}><img src={deleteNew} /></button></div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>
              {productData.length === 0 && (
                <p className="text-center mt-3">Record Not Found!</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export default Dashboard;
