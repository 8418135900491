import React, { useState, useEffect } from "react";
import sort from "../../assets/img/sort.svg";
import search from "../../assets/img/search.svg";
import edit from "../../assets/img/edit.svg";
import deleteNew from "../../assets/img/delete.svg";
import three_dots from "../../assets/img/three_dots.svg";
import Pagination from '../../component/Pagination';
import { postDataService } from "../../component/userService";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import LoaderComponent from "../../component/LoaderComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let PageSize = 10;

const CategoryList = () => {
    const [Data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState('');
    const [sortBy, setSortBy] = useState('-1');
    const [loading, setLoading] = useState(false);
    const [isData, setIsData] = useState(false);

    useEffect(() => {
        if (filter === "") {
            getData(currentPage, filter, sortBy);
        }
    }, [filter])

    async function getData(currentPage, filter, sortBy) {
        var data = {
            pageNumber: currentPage,
            PageSize: PageSize,
            filter: filter,
            sortBy: sortBy
        }
        setLoading(true);
        setIsData(false);
        await postDataService(`get_category`, new URLSearchParams(data))
            .then(response => {
                setLoading(false);
                if (response.status === 'success') {
                    setTotal(response.data.total);
                    setData(response.data.categoryList);
                    setCurrentPage(currentPage);
                    if (response.data.categoryList.length === 0) {
                        setIsData(true);
                    }
                } else {
                    toast.dismiss();
                    toast.error(response.message);
                    console.log(response.message);
                }
            });
    }

    // Change Status
    async function changeStatus(_id, status) {
        var changeStatus = status;
        var categoryData = {
            _id: _id,
            status: changeStatus
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await postDataService(`change_category_status`, new URLSearchParams(categoryData))
                    .then(response => {
                        if (response.status === 'success') {
                            Swal.fire(
                                changeStatus + '!',
                                'Category Deleted Successfully!',
                                'success'
                            )
                            getData(currentPage, filter, sortBy);
                        } else {
                            Swal.fire(
                                changeStatus + '!',
                                'Unable To Delete!',
                                'error'
                            )
                        }
                    });
            }
        })

    }

    const handleFilterChange = e => {
        if (e.key === "Enter") {
            setCurrentPage(1);
            getData(1, filter, sortBy);
        }
    };

    function pageChange(page) {
        getData(page, filter, sortBy);
    }

    function sortMember(sortBy) {
        setSortBy(sortBy);
        getData(currentPage, filter, sortBy);
    }

    return (
        <>
            <div>
                <LoaderComponent active={loading} />
            </div>
            <section class="content_sec">
                <ToastContainer closeButton={true} position="top-right" />
                <div class="content_table">
                    <div class="content_table_itm card">
                        <div class="content_table_head">
                            <div class="content_table_head_cn">
                                <p>Malware Type</p>
                                <p class="content_table_head_cn_prc">You have {total}</p>
                            </div>
                            <div class="content_table_head_sort">
                                <div class="cn_t_sort input-group select-group">
                                    <div class="cn_t_sort_ic input-group-addon"><img src={sort} /></div>
                                    <select class="form-control" onChange={(e) => sortMember(e.target.value)}>
                                        <option>Sort</option>
                                        <option value="1">ASC</option>
                                        <option value="-1">DESC</option>
                                    </select>
                                </div>
                            </div>
                            <div class="content_table_head_search">
                                <div class="cn_t_search">
                                    <div class="marwal_r_search">
                                        <div class="marwal_search_ic"><img src={search} /></div>
                                        <input type="text" placeholder="Search here..." value={filter}
                                            onKeyPress={handleFilterChange} onChange={(e) => setFilter(e.target.value)} class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content_table_itm card">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Data.map((item, i) => {
                                        return (
                                            <tr>
                                                <td>
                                                    {(currentPage - 1) * PageSize + i + 1}
                                                </td>
                                                <td>
                                                    <div class="check_itm">{item.name}</div>
                                                </td>
                                                <td class="table_small_data">{item.status === 'Active' ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>}</td>
                                                <td class="action_td">
                                                    <div class="action_a"><img src={three_dots} /></div>
                                                    <div class="action_table">
                                                        <div class="action_table_itm"><Link to={"/malware-type/edit/" + item._id}><img src={edit} /></Link></div>
                                                        <div class="action_table_itm"><button onClick={(e) => { changeStatus(item._id, 'Deleted') }}><img src={deleteNew} /></button></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {isData && (
                                <p className="text-center mt-3">Record Not Found!</p>
                            )}
                        </div>
                    </div>

                </div>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={total}
                    pageSize={PageSize}
                    onPageChange={page => pageChange(page)}
                />
            </section>
        </>
    );
};


export default CategoryList;
