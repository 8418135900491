
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import icon_logo from "../assets/img/logo_new1.png";
import ic1 from "../assets/img/ic1.png";
import ic2 from "../assets/img/ic2.svg";
import ic3 from "../assets/img/ic3.svg";
import ic4 from "../assets/img/ic4.svg";
import ic5 from "../assets/img/ic5.svg";

import Logo from "../assets/img/Logo.png";
import menu from "../assets/img/menu.svg";
import notification from "../assets/img/notification.svg";
import cross from "../assets/img/cross.svg";
import setting from "../assets/img/setting.svg";
import user_img from "../assets/img/user_img.png";
import Swal from "sweetalert2";


export default () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(window.location.pathname);
  const [isNav, setIsNav] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)

    if (isNav) {
      document.body.classList.add('menuactive');
    } else {
      document.body.classList.remove('menuactive');
    }
  }, [isNav])

  // Logout
  async function logout() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.clear()
        navigate("/");
      }
    })

  }

  return (
    <section class="sidebar">
      <div class="sidebar_in">
        <div class="sidebar_logo"><Link onClick={() => setIsActive('/dashboard')} to="/dashboard"><img class="desktop_icon" src={icon_logo} /><img class="mobile_logo" src={icon_logo} /></Link></div>
        <button class="navbar-toggler" type="button" onClick={() => setIsNav(isNav => !isNav)}>
          <img src={menu} />
        </button>
        <div class="cross_ic_itm" onClick={() => setIsNav(isNav => !isNav)} >
          <a class="cross_ic"><img src={cross} /></a>
        </div>
        <ul class="sidebar_itms">
          <li class="sidebar_itm sidebar_itm_user">
            <div class="navbar_item"><a href="#"><img src={notification} /></a></div>
            <div class="navbar_item"><a href="#"><img src={setting} /></a></div>
            <div class="navbar_item"><a href="#"><img src={user_img} /></a></div>
          </li>
          <li class="sidebar_itm">
            <Link to="/dashboard" onClick={() => setIsActive('/dashboard')} className={isActive === '/dashboard' ? 'active' : ''}>
              <div className="sidebar_ic"><img src={ic1} /></div>
              <span className="sidebar_cn">Overview</span>
            </Link>
          </li>
          <li class="sidebar_itm">
            <Link to="/source-code" onClick={() => setIsActive('/source-code')} className={isActive === '/source-code' ? 'active' : ''}>
              <div className="sidebar_ic"><img src={ic2} /></div>
              <span className="sidebar_cn">Projects</span>
            </Link>
          </li>
          <li class="sidebar_itm">
            <Link to="/source-code" data-bs-toggle="collapse" data-bs-target="#demo" class="collapsed dropdown-toggle" aria-expanded="false">
              <div class="sidebar_ic"><img src={ic4} /></div>
              <span class="sidebar_cn">Categories</span>
            </Link>
            <ul class="list-unstyled">
              <li class="sidebar_itm collapse" id="demo" >
                <Link to="/malware-type" onClick={() => setIsActive('/malware-type')} className={isActive === '/malware-type' ? 'active' : ''}>
                  <div class="sidebar_ic"></div>
                  <span class="sidebar_cn">Malware Type</span>
                </Link>
              </li>
              <li class="sidebar_itm collapse" id="demo">
                <Link to="/technology" onClick={() => setIsActive('/technology')} className={isActive === '/technology' ? 'active' : ''}>
                  <div class="sidebar_ic"></div>
                  <span class="sidebar_cn">Technology</span>
                </Link>
              </li>
              <li class="sidebar_itm collapse" id="demo">
                <Link to="/operating-system" onClick={() => setIsActive('/operating-system')} className={isActive === '/operating-system' ? 'active' : ''}>
                  <div class="sidebar_ic"></div>
                  <span class="sidebar_cn">OS</span>
                </Link>
              </li>
            </ul>
          </li>
          <li class="sidebar_itm">
            <Link to="/members" onClick={() => setIsActive('/members')} className={isActive === '/members' ? 'active' : ''}>
              <div className="sidebar_ic"><img src={ic3} /></div>
              <span className="sidebar_cn">Members</span>
            </Link>
          </li>
          <li class="sidebar_itm">
            <Link to="/plan" onClick={() => setIsActive('/plan')} className={isActive === '/plan' ? 'active' : ''}>
              <div class="sidebar_ic"><img src={ic4} /></div>
              <span class="sidebar_cn">Plan</span>
            </Link>
          </li>
          <li class="sidebar_itm">
            <Link to="/source-code" data-bs-toggle="collapse" data-bs-target="#demo_analytics" class="collapsed dropdown-toggle" aria-expanded="false">
              <div class="sidebar_ic"><img src={ic4} /></div>
              <span class="sidebar_cn">Analytics</span>
            </Link>
            <ul class="list-unstyled">
              <li class="sidebar_itm collapse" id="demo_analytics" >
                <Link to="/feature" onClick={() => setIsActive('/feature')} className={isActive === '/feature' ? 'active' : ''}>
                  <div class="sidebar_ic"></div>
                  <span class="sidebar_cn">Feature</span>
                </Link>
              </li>
              <li class="sidebar_itm collapse" id="demo_analytics" >
                <Link to="/downloaded-code" onClick={() => setIsActive('/downloaded-code')} className={isActive === '/downloaded-code' ? 'active' : ''}>
                  <div class="sidebar_ic"></div>
                  <span class="sidebar_cn">Download Code</span>
                </Link>
              </li>
              <li class="sidebar_itm collapse" id="demo_analytics">
                <Link to="/bookmarked-code" onClick={() => setIsActive('/bookmarked-code')} className={isActive === '/bookmarked-code' ? 'active' : ''}>
                  <div class="sidebar_ic"></div>
                  <span class="sidebar_cn">Bookmarked Code</span>
                </Link>
              </li>
              <li class="sidebar_itm collapse" id="demo_analytics">
                <Link to="/reported-code" onClick={() => setIsActive('/reported-code')} className={isActive === '/reported-code' ? 'active' : ''}>
                  <div class="sidebar_ic"></div>
                  <span class="sidebar_cn">Reported code</span>
                </Link>
              </li>
              <li class="sidebar_itm collapse" id="demo_analytics">
                <Link to="/copied-code" onClick={() => setIsActive('/copied-code')} className={isActive === '/copied-code' ? 'active' : ''}>
                  <div class="sidebar_ic"></div>
                  <span class="sidebar_cn">Copied code</span>
                </Link>
              </li>
            </ul>
          </li>
          <li class="sidebar_itm">
            <button onClick={(e) => { logout() }}>
              <div class="sidebar_ic"><img src={ic5} /></div>
              <span class="sidebar_cn">Logout</span>
            </button>
          </li>
        </ul>
      </div>
    </section>

  );
};
