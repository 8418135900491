import React, { useState, useEffect } from "react";
import { getDataService, postDataService } from "../../component/userService";
import { useParams, useNavigate } from "react-router-dom";
import LoaderComponent from "../../component/LoaderComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FeatureAdd = () => {
  const history = useNavigate();
  var data = useParams();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [nameError, setNameError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      getIndividualData();
    }
  }, [])

  async function getIndividualData() {
    await getDataService(`get_individual_feature/${data.id}`)
      .then(response => {
        if (response.status === 'success') {
          setName(response.data.name);
          setDescription(response.data.description);
        } else {
          toast.error(response.message);
        }
      });
  }

  // Validation
  const handleValidation = (event) => {
    let formIsValid = false;
    if (!name) {
      setNameError("Name is required!");
      return false;
    } else {
      setNameError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (handleValidation()) {
      if (Object.keys(data).length !== 0) {
        var featureData = {
          name: name,
          description: description,
          _id: data.id
        }
        setLoading(true);
        await postDataService(`update_feature`, new URLSearchParams(featureData))
          .then(response => {
            setLoading(false);
            if (response.status === 'success') {
              toast.success(response.message);
              setTimeout(() => {
                history("/feature");
              }, 1000);
            } else {
              toast.error(response.message);
            }
          });

      } else {

        var featureData = {
          name: name,
          description: description
        }

        await postDataService(`add_feature`, new URLSearchParams(featureData))
          .then(response => {
            if (response.status === 'success') {
              toast.success(response.message);
              setTimeout(() => {
                history("/feature");
              }, 1000);
            } else {
              toast.error(response.message);
            }
          });
      }

    }
  }

  return (
    <>
      <div>
        <LoaderComponent active={loading} />
      </div>
      <section class="content_sec">
        <ToastContainer closeButton={true} position="top-right" />
        <div class="content_table">
          <div class="content_table_card card">
            <div class="content_table_head">
              <div class="content_table_head_cn">
                <p>Add Feature</p>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div class="card upload_crad">
              <div class="upload_crad_in">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Name <span className="text-danger">*</span></label>
                      <div class="form_input">
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} class="form-control" placeholder="Enter Name" />
                        <small className="text-danger form-text">
                          {nameError}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="form-group">
                      <label>Description</label>
                      <div class="form_input">
                        <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} rows="4" cols="50" placeholder="Enter Description"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div class="cn_t_add">
                    <button type="submit" class="btn btn_primary submitButton">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};


export default FeatureAdd;
